import { CustomInformationFields } from '@/features/onboarding/custom-information/utils';
import { FeeAmortizationDetailsType } from '@/features/onboarding/loan-details/fee-amortization-section/FeeAmortizationDetailsType';
import { LoanFacility } from './facility';
import type { ReconcileOnEnum, TransactionType } from './transactions';
import { MambuUser } from 'kennek/interfaces/accounts';
import { InvestorRow } from 'kennek/interfaces/accounts/investor';
import { Currencies } from 'kennek/interfaces/loans';

export interface Loan {
  accountHolderKey?: string;
  accountState: string;
  assignedCentreKey?: string;
  assignedBranchKey?: string;
  assignedUserKey?: string;
  balances?: Balance;
  groupId?: string;
  encodedKey?: string;
  externalId?: string;
  disbursementDetails?: DisbursementDetails;
  firstRepaymentDate?: string;
  graceAmount?: ScheduleSettings['graceAmount'];
  gracePeriod?: number;
  holderType?: string;
  id?: string;
  interestSettings?: InterestSettings;
  loanAmount?: number;
  loanName?: string;
  payCurrentInstallment?: number;
  minimumInterestPeriod?: number;
  loanPenaltyCalculationMethod?: LoanPenaltyCalculationMethod;
  loanPenaltyTolerancePeriod?: string;
  mambuUser?: MambuUser;
  paymentHoliday?: number;
  rollUpDuration?: number;
  defaultInterestRate?: number;
  paymentPlan?: Array<PaymentPlan>;
  penaltyRate?: number;
  periodicPayment?: number;
  productTypeKey?: string;
  productType?: string;
  currency?: Currencies;
  scheduleSettings?: ScheduleSettings;
  state?: LoanState;
  subState?: LoanSubState;
  tranches?: Tranche[];
  useDisbursementToCalculateRepaymentSchedule?: boolean;

  netLoanCalculationMethod?: NET_LOAN_CALCULATION_METHOD_OPTIONS;
  gdc?: number;
  ltc?: number;
  ltv?: number;
  gdv?: number;
  interestGraceHandleMethod?: InterestGraceHandleMethod;
  exitFeeAmount?: number;
  factorAmount?: number;
  maturityDate?: string;
  capFeesIncludedInLoanAmount?: boolean;
  amountToFundType?: AmountToFundType;
  feeSettings?: FeeSettings;
  counterpartyId?: string;
  counterparty?: Counterparty;
  borrowerContactFullName?: string;
  rollUpDurationLinkedLoanType?: 'MAIN' | 'LINKED';
  executionId?: string;
  fundingMethodId?: string;
  investmentAmountType?: InvestmentAmountType;
}

export interface LinkedLoan extends Omit<Loan, 'currency'> {
  approvedDate: string;
  accountSubState: LoanState;
  currency: {
    currencyCode: string;
    code: string;
  };
}

export interface LoanActivationStatus {
  status: 'BLOCKED' | 'READY_TO_ACTIVATE';
  reason?: {
    name: string;
    message: string;
  };
  data?: {
    executionId: string;
  };
}

export type NetLoanCalculation = {
  netLoanCalculationMethod?: NET_LOAN_CALCULATION_METHOD_OPTIONS;
  gdc?: number;
  ltc?: number;
  ltv?: number;
  gdv?: number;
};

export type TrancheUpdateFeeItem = {
  amount: number;
  predefinedFeeEncodedKey: string;
};

export type Tranche = {
  amount: number;
  disbursementDate: string;
  disbursed?: boolean;
  fees?: CapitalizedFee[];
  disbursedAmount?: number;
  id?: string;
  facilities?: Partial<LoanFacility>[];
  transactionEncodedKey?: string;
  counterpartyId?: string;
  counterparty?: Counterparty;
  expectedRetainedInterest?: number;
};

export type Counterparty = {
  id: string;
  type: string;
  bankAccountHolderName: string;
  bankAccountSortCode: string;
  bankAccountNumber: string;
  description?: string;
};

export type TrancheUpdate = {
  id?: string;
  fees?: TrancheUpdateFeeItem[];
  disbursementDate: string;
  amount: number;
  transactionEncodedKey?: string;
};

export type TrancheView = {
  id?: string;
  amount: number;
  disbursementDate: string;
  disbursed?: boolean;
  fees?: CapitalizedFeeView[];
  disbursedAmount?: number;
  currency?: Currencies;
  facilities?: Partial<LoanFacility>[];
  transactionEncodedKey?: string;
};

export type DisbursementDetails = {
  expectedDisbursementDate?: string;
  disbursementDate?: string;
  fees?: CapitalizedFee[];
  previewDisbursementDate?: string;
};

export enum AmountToFundType {
  NET = 'net',
  GROSS = 'gross',
  CUSTOM = 'custom',
}

export enum InvestmentAmountType {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export type CapitalizedFee = {
  amount: number;
  calculationMethod?: string;
  predefinedFeeEncodedKey: string;
  customId?: string;
  name?: string;
  trigger?: FeeTrigger;
};

export type CapitalizedFeeView = {
  amount: number;
  calculationMethod?: 'FLAT' | 'LOAN_AMOUNT_PERCENTAGE';
  predefinedFeeEncodedKey: string;
  name?: string;
};

export type DisburseTrancheMutationInput = {
  fees?: CapitalizedFee[];
  trancheId?: string;
  facilitiesAllocations?: {
    facilityId: string;
    amount: number;
  }[];
  counterpartyId?: string;
  applyInterestRetained?: boolean;
};

export interface ScheduleSettings {
  graceAmount?: number;
  gracePeriod?: number;
  gracePeriodType?: string;
  monthlyRepaymentDay?: number;
  paymentHoliday?: number;
  rollUpDuration?: number;
  repaymentInstallments?: number;
  repaymentPeriodCount?: number;
  repaymentPeriodUnits?: RepaymentPeriodUnits;
  repaymentScheduleMethod?: RepaymentScheduleMethod;
  shortMonthHandlingMethod?: keyof typeof ShortMonthHandlingMethod | null;
  periodicPaymentAmount?: number;
  balloonPaymentAmount?: number;
  balloonPaymentPercentage?: number;
}

export interface InterestSettings {
  calculationMethod?: keyof typeof CalculationMethod | null;
  chargeFrequency?: ChargeFrequency | null;
  interestType?: InterestType;
  interestGraceInstallments?: number;
  interestGraceHandleMethod?: InterestGraceHandleMethod;
  /**
   * Current interest spread not adjusted to any margins
   */
  interestSpread?: number;
  /**
   * Current interest rate not adjusted to any margins
   */
  interestRate?: number;
  /**
   * Current interest rate not adjusted to any margins
   */
  rate?: number;
  /**
   * Adjusted to user interest margins, reflects the actual interest without graces
   */
  displayedInterestRate?: number;
  investorInterest?: number;
  investorType?: InvestorType;
  interestGraceDeducted?: number;
  interestCapitalisationFrequency?: InterestCapitalisationFrequency;
  interestCeiling?: number | null;
  interestFloor?: number | null;
  initialInterestRateAsCeiling?: boolean | null;
  initialInterestRateAsFloor?: boolean | null;
  indexRateType?: IndexInterestRateTypeDto;
}

export enum IndexInterestRateTypeDto {
  FIXED = 'FIXED',
  INDEX = 'INDEX',
}

export interface FeeSettings {
  feeAmortizationDetails?: FeeAmortizationDetailsType;
}

export type InvestorType = 'INVESTOR';

export enum CalculationMethod {
  DECLINING_BALANCE = 'DECLINING_BALANCE',
  DECLINING_BALANCE_DISCOUNTED = 'DECLINING_BALANCE_DISCOUNTED',
  FLAT = 'FLAT',
}

export enum InterestGraceHandleMethod {
  DEDUCT_ON_DISBURSEMENT = 'DEDUCT_ON_DISBURSEMENT',
  DO_NOT_SERVICE = 'DO_NOT_SERVICE',
}

export enum InterestCapitalisationFrequency {
  AT_THE_END_OF_THE_MONTH = 'AT_THE_END_OF_THE_MONTH',
  AT_THE_INSTALLMENT_DUE_DATE = 'AT_THE_INSTALLMENT_DUE_DATE',
}

export type ChargeFrequency =
  | 'ANNUALIZED'
  | 'EVERY_DAY'
  | 'EVERY_FOUR_WEEKS'
  | 'EVERY_MONTH'
  | 'EVERY_WEEK';

export type LoanPenaltyCalculationMethod =
  | 'NONE'
  | 'OVERDUE_BALANCE'
  | 'OVERDUE_BALANCE_AND_INTEREST'
  | 'OUTSTANDING_PRINCIPAL';

export type RepaymentPeriodUnits = 'DAYS' | 'WEEKS' | 'MONTHS' | 'YEARS';

export type RepaymentScheduleMethod = 'NONE' | 'FIXED' | 'DYNAMIC';

export enum ShortMonthHandlingMethod {
  FIRST_DAY_OF_NEXT_MONTH = 'FIRST_DAY_OF_NEXT_MONTH',
  LAST_DAY_IN_MONTH = 'LAST_DAY_IN_MONTH',
}

export enum NET_LOAN_CALCULATION_METHOD_OPTIONS {
  LTC = 'LTC',
  LTV = 'LTV',
  STATED = 'STATED',
}

export interface PaymentPlan {
  amount: number;
  toInstallment: number;
}

export type StepsEnum =
  | 'COMPANY'
  | 'USER'
  | 'LOAN_DETAILS'
  | 'SECURITIES'
  | 'LOAN_REPORTING'
  | 'UPLOAD_DOCUMENTATION'
  | 'INVESTOR'
  | 'CUSTOM_INFORMATION'
  | 'SUMMARY';

export type TaskStatus = 'COMPLETED' | 'PENDING';

export interface OnboardingTasks {
  createdAt: string;
  name?: StepsEnum;
  updatedAt: string;
  status?: TaskStatus;
}

export interface Borrower {
  amount?: number;
  borrowerEmail: string;
  companyName?: string;
  createdAt?: string;
  id?: string;
  encodedKey?: string;
  loanProductEncodedKey?: string;
  numberOfInstallments?: number;
  onboardingTasks?: OnboardingTasks[];
  rate?: number;
  startDate?: string;
  type?: string;
}

export interface BorrowerLoan extends Borrower {
  accountHolderKey?: string;
}

export interface LoanSubStepsStatus {
  LOAN_INFORMATION: boolean;
  LOAN_REPAYMENT: boolean;
  LOAN_REPAYMENT_SCHEDULE: boolean;
}

export interface MakePaymentFacilityItem {
  amount: number;
  loanFacilityId: string;
}

export interface Payment {
  amount?: number;
  borrowerId?: string;
  date?: string;
  description?: string;
  loanId?: string;
  paymentAmount?: string;
  performedBy?: string;
  reconcileOn?: ReconcileOnEnum;
  type?: TransactionType;
  facilities?: MakePaymentFacilityItem[];
}

export interface LoanProductRules {
  repaymentPeriodUnits?: RepaymentPeriodUnits[];
  prefilledFields?: PrefilledFields[];
  repaymentScheduleEditOptions: RepaymentScheduleEditOptions;
  principalCollectionFrequency: number;
  rules?: {
    graceAmount?: LoanProductRulesValues;
    gracePeriod?: LoanProductRulesValues;
    interestRate?: LoanProductRulesValues;
    loanAmount?: LoanProductRulesValues;
    monthlyRepaymentDay?: LoanProductRulesValues;
    penalty?: LoanProductRulesValues;
    repaymentInstallments?: LoanProductRulesValues;
    paymentHoliday?: {
      defaultValue: number;
    };
  };
  variableRequiredFields?: VariableRequiredFields[];
  fees?: FeeRule[];
  lateRepaymentFees?: FeeRule[];
  maxNumberOfTranches?: number;
  minimumInterestPeriod?: boolean;
  payCurrentEnabled?: boolean;
  currency?: Currencies;
  assetType?: boolean;
  rollUp?: boolean;
  rollUpDuration?: boolean;
  rollUpDuration2?: boolean;
  defaultInterestRate?: boolean;
  interestGraceHandleMethodEnabled?: boolean;
  exitFeeEnabled?: boolean;
  tranchesEditionEnabled?: boolean;
  interestBalanceCalculationMethod?: string;
  interestRateEditionEnabled?: boolean;
  interestCalculationMethod?: string;
  factorLoan?: boolean;
  paymentHoliday?: boolean;
  holidays?: HolidaysResponse;
  facilities?: boolean;
  productType: ProductTypesNames;
  interestRateSource?: string;
  revenueBasedFactorLoan?: boolean;
  baseIndexRate?: string;
  interestGrace?: boolean;
  disbursementBankAccountDetails?: boolean;
  earlyPaymentReconciliationOptionsEnabled?: boolean;
  serviceFullInterestPeriod?: boolean;
  unutilisedInterest?: boolean;
  platformIndexRateEnabled?: boolean;
  balloonPaymentEnabled?: boolean;
  indexRateFirstApplicableDate?: string;
  currentIndexRateValue?: number;
}

export enum ProductTypesNames {
  DYNAMIC_TERM_LOAN = 'DYNAMIC_TERM_LOAN',
  FIXED_TERM_LOAN = 'FIXED_TERM_LOAN',
  TRANCHED_LOAN = 'TRANCHED_LOAN',
  REVOLVING_CREDIT = 'REVOLVING_CREDIT',
  INTEREST_FREE_LOAN = 'INTEREST_FREE_LOAN',
}

export type FeeTrigger =
  | 'UPFRONT_DISBURSEMENT'
  | 'CAPITALIZED_DISBURSEMENT'
  | 'DISBURSEMENT';

export type FeeRule = {
  amount?: number;
  calculationMethod: FeeCalculationMethod;
  id: string;
  name: string;
  customId?: string;
  percentageAmount?: number;
  required: boolean;
  trigger: FeeTrigger;
};

export type FeeCalculationMethod = 'FLAT' | 'PERCENTAGE';

export type PrefilledFields =
  | { name: 'repaymentPeriodCount'; value: number }
  | { name: 'repaymentPeriodUnits'; value: RepaymentPeriodUnits }
  | { name: 'monthlyRepaymentDay'; value: number }
  | { name: 'interestType'; value: InterestType }
  | { name: 'interestChargeFrequency'; value: ChargeFrequency };

export type PrefilledFieldsNames = PrefilledFields extends { name: infer T }
  ? T
  : never;

export type InterestType = 'COMPOUNDING_INTEREST' | 'SIMPLE_INTEREST';

export interface RepaymentScheduleEditOptions {
  adjustDates: boolean;
  adjustFee: boolean;
  adjustHolidays: boolean;
  adjustInterest: boolean;
  adjustPenalty: boolean;
  adjustPrincipal: boolean;
}
export interface LoanProductRulesValues {
  defaultValue?: number;
  maxValue?: number;
  minValue?: number;
}
export interface VariableRequiredFields {
  type: number | string;
  name:
    | 'penaltyRate'
    | 'periodicPayment'
    | 'periodicPaymentFrom'
    | 'periodicPaymentTo'
    | 'monthlyRepaymentDay';
}

export interface BorrowerDocuments {
  draft?: boolean;
  cognitoId?: string;
  createdAt?: string;
  email?: string;
  fileName?: string;
  mimetype?: string;
  size?: number;
  storageKey?: string;
  uploadedByEmail?: string;
  uploadedByUserName?: string;
  updatedAt?: string;
  userName?: string;
  __v?: number;
  _id?: string;
  isDraftDeleted?: boolean;
}

export interface EditBorrowerDocuments extends BorrowerDocuments {
  editId?: string;
}

export interface PaymentSchedule {
  capitalizedFees?: number;
  deductedFees?: number;
  onDisbursementFees?: number;
  installments: Installment[];
  currency?: Currencies;
  predictedCashflowTransactions?: Installment[];
}

export interface PaymentGroupSchedule {
  firstRepaymentDate: string;
  installments: Installment[];
  leftToPay: number;
  remainingInstallmentsCount: number;
  totalAmount: number;
  totalRepaymentAmount: number;
}

export interface Installment {
  accruedInterest: number;
  balance: number;
  days: number;
  defaultInterest?: number;
  dueDate: string;
  encodedKey: string;
  feeDue: number;
  feeExpected: number;
  feePaid: number;
  interestDue: number;
  interestExpected: number;
  interestPaid: number;
  maturityDate: string;
  number: string;
  paidDate: string;
  penaltyDue: number;
  penaltyExpected: number;
  penaltyPaid: number;
  principal: number;
  principalDue: number;
  principalExpected: number;
  principalOutstanding: number;
  principalOutstandingDisplay: number;
  principalPaid: number;
  // @refactor state replace string type by ENUM or type
  state: string;
  taxDue: number;
  taxExpected: number;
  taxPaid: number;
  totalAccruedInterest: number;
  totalDue: number;
  totalExpected: number;
  totalOutstanding: number;
  totalPaid: number;
  currency?: Currencies;
  previewDueDate?: string;
  subInstalments?: Installment[];
  borowerName?: string;
}

export interface UpdateActiveInstallment {
  installments: ActiveInstallment[];
}

export interface ActiveInstallment {
  encodedKey: string;
  principalDue: number;
}

export interface Balance {
  feesBalance: number;
  feesDue: number;
  feesPaid: number;
  interestBalance: number;
  interestDue: number;
  interestPaid: number;
  penaltyBalance: number;
  penaltyDue: number;
  penaltyPaid: number;
  principalBalance: number;
  principalDue: number;
  principalPaid: number;
  payoffTotalAmount: number;
  payoffInterestBalance: number;
  minimumInterestOutstanding: number;
  unutilisedInterest: number;
}

export interface NewBalance {
  fee?: Balance['feesBalance'];
  interest?: Balance['interestBalance'];
  penalty?: Balance['penaltyBalance'];
}

export interface CreateLoan {
  accountHolderKey: Loan['accountHolderKey'];
  assignedBranchKey: Loan['assignedBranchKey'];
  assignedCentreKey: Loan['assignedCentreKey'];
  disbursementDate: string;
  externalId: Loan['externalId'];
  fees?: CapitalizedFee[];
  firstRepaymentDate: Loan['firstRepaymentDate'];
  graceAmount: ScheduleSettings['graceAmount'];
  gracePeriod: Loan['gracePeriod'];
  interestRate: InterestSettings['rate'];
  defaultInterestRate: Loan['defaultInterestRate'];
  loanName: Loan['loanName'];
  loanAmount: Loan['loanAmount'];
  monthlyRepaymentDay?: number;
  paymentHoliday: ScheduleSettings['paymentHoliday'];
  rollUpDuration?: ScheduleSettings['rollUpDuration'];
  paymentPlan: Loan['paymentPlan'];
  penaltyRate: Loan['penaltyRate'];
  interestGraceInstallments: InterestSettings['interestGraceInstallments'];
  periodicPayment: Loan['periodicPayment'];
  productTypeKey: Loan['productTypeKey'];
  repaymentInstallments: ScheduleSettings['repaymentInstallments'];
  payCurrentInstallment: number;
  minimumInterestPeriod: number;
  repaymentPeriodCount: ScheduleSettings['repaymentPeriodCount'];
  repaymentPeriodUnits: ScheduleSettings['repaymentPeriodUnits'];
  tranches?: Tranche[];
  loanFacilities?: LoanFacility[];
  netLoanCalculationMethod: Loan['netLoanCalculationMethod'];
  ltv: Loan['ltv'];
  gdv: Loan['gdv'];
  ltc: Loan['ltc'];
  gdc: Loan['gdc'];
  interestGraceHandleMethod: Loan['interestGraceHandleMethod'];
  exitFeeAmount: Loan['exitFeeAmount'];
  factorAmount: Loan['factorAmount'];
  interestCapitalisationFrequency?: InterestSettings['interestCapitalisationFrequency'];
  assets?: Asset[];
  feeSettings?: Loan['feeSettings'];
  counterpartyId?: Loan['counterpartyId'];
  interestCeiling?: InterestSettings['interestCeiling'];
  interestFloor?: InterestSettings['interestFloor'];
  useInitialInterestRateAsCeiling?: InterestSettings['initialInterestRateAsCeiling'];
  useInitialInterestRateAsFloor?: InterestSettings['initialInterestRateAsFloor'];
  executionId?: Loan['executionId'];
  balloonPaymentAmount?: ScheduleSettings['balloonPaymentAmount'];
}

export interface RestructureLoan {
  disbursementDate?: string;
  externalId?: Loan['externalId'];
  fees: CapitalizedFee[];
  firstRepaymentDate?: Loan['firstRepaymentDate'];
  graceAmount?: ScheduleSettings['graceAmount'];
  gracePeriod?: Loan['gracePeriod'];
  id?: Loan['id'];
  interestRate?: InterestSettings['rate'];
  defaultInterestRate?: Loan['defaultInterestRate'];
  rollUpDuration?: ScheduleSettings['rollUpDuration'];
  interestGraceInstallments: InterestSettings['interestGraceInstallments'];
  interestGraceHandleMethod: Loan['interestGraceHandleMethod'];
  minimumInterestPeriod: Loan['minimumInterestPeriod'];
  loanAmount: number;
  loanName?: Loan['loanName'];
  monthlyRepaymentDay?: number;
  newBalances?: NewBalance;
  newPrincipal?: Installment['principalDue'];
  paymentHoliday?: ScheduleSettings['paymentHoliday'];
  penaltyRate?: Loan['penaltyRate'];
  periodicPayment?: Loan['periodicPayment'];
  balloonPaymentAmount?: ScheduleSettings['balloonPaymentAmount'];
  productTypeKey?: Loan['productTypeKey'];
  repaymentInstallments?: ScheduleSettings['repaymentInstallments'];
  repaymentPeriodCount?: ScheduleSettings['repaymentPeriodCount'];
  repaymentPeriodUnits?: ScheduleSettings['repaymentPeriodUnits'];
  netLoanCalculationMethod: Loan['netLoanCalculationMethod'];
  ltv?: Loan['ltv'];
  gdv?: Loan['gdv'];
  ltc?: Loan['ltc'];
  gdc?: Loan['gdc'];
  exitFeeAmount?: Loan['exitFeeAmount'];
  newFactorAmount?: Loan['factorAmount'];
  tranches?: Tranche[];
  loanFacilities?: LoanFacility[];
  interestCapitalisationFrequency?: InterestSettings['interestCapitalisationFrequency'];
  payCurrentInstallment?: number;
  investments?: Pick<
    InvestorRow,
    | 'investorId'
    | 'lenderOfRecordId'
    | 'amount'
    | 'investorInterest'
    | 'investmentType'
  >[];
  assets?: Asset[];
  amountToFundType: AmountToFundType;
  fundingMethodId?: string;
  feeSettings?: Loan['feeSettings'];
  counterpartyId?: Loan['counterpartyId'];
  interestCeiling?: InterestSettings['interestCeiling'];
  interestFloor?: InterestSettings['interestFloor'];
  useInitialInterestRateAsCeiling?: InterestSettings['initialInterestRateAsCeiling'];
  useInitialInterestRateAsFloor?: InterestSettings['initialInterestRateAsFloor'];
  customInformation?: CustomInformationFields[];
  repaymentScheduleFile?: string;
}

export interface UpdateLoan extends CreateLoan {
  id: Loan['id'];
}

export interface AddLoanToGroup {
  groupId: string;
  loanId: string;
}

export interface Asset {
  borrowerType: string;
  assetType: string;
  loanToValuePercentage?: number;
  assetValuation: number;
  assetDescription: string;
  securityAddress?: string; //only for asset type of type property
  occupancyStatus?: OccupancyStatus; //only for asset type of type property
  loanEncodedKey?: string;
}

export interface AssetDetails {
  assetType: string;
  assetLabel: string;
  order: number;
}

export type OccupancyStatus = 'VACANT' | 'TENANTED' | 'OWNER_OCCUPIED';

export interface TransactionChannel {
  transactionChannelId: string;
  transactionChannelKey: string;
  transactionChannelName: string;
}

export interface PayoffPayload {
  loanId: Loan['encodedKey'];
  performedBy: string;
  type: TransactionType;
  transactionChannelId: TransactionChannel['transactionChannelId'];
  transactionChannelKey: TransactionChannel['transactionChannelKey'];
  interestWriteOff?: number;
}

export interface LoanPayoffBalances {
  minimumInterestOutstanding?: number;
  unutilisedInterest?: number;
  payoffTotalAmount?: number;
  payoffInterestBalance?: number;
  payoffFeesBalance?: number;
}

export interface LoanPayoff {
  dailyInterestAccrual?: number;
  balances: LoanPayoffBalances;
}

export type LoanState =
  | 'UNAPPROVED'
  | 'APPROVED'
  | 'RESTRUCTURED'
  | 'REPAID'
  | 'DELETED'
  | 'CLOSURE_SCHEDULED';

export type LoanSubState =
  | 'PARTIALLY_DISBURSED'
  | 'LOCKED'
  | 'LOCKED_CAPPING'
  | 'REFINANCED'
  | 'RESCHEDULED'
  | 'WITHDRAWN'
  | 'REPAID'
  | 'REJECTED'
  | 'WRITTEN_OFF'
  | 'TERMINATED';

export interface HolidaysResponse {
  dates: HolidayDate[];
  nonWorkingDays: NonWorkingDaysEnum[];
}
export interface HolidayDate {
  date: string;
  isAnnuallyRecurring: boolean;
}

export enum NonWorkingDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface NonWorkingDays {
  nonWorkingDays: NonWorkingDaysEnum[];
}

export type CounterpartyDto = {
  id: string;
  type: string;
  bankAccountHolderName: string;
  bankAccountSortCode: string;
  bankAccountNumber: string;
  description?: string;
};

export type CreateCounterpartyDto = Omit<CounterpartyDto, 'id'>;
